.l-information-page {
  &__text-container {
    text-align: center;
    padding-left: 3rem;
    padding-right: 3rem;
    .title {
      color: var(--primary-color);
      font-weight: 700;
    }

    .paragraph {
      font-size: 1.6rem;
      line-height: 1.5;
    }
  }

  &__header {
    .img--circle {
      width: 100%;
      // height: 30rem;
      // width: 40%;
      // max-width: 50rem;
      // max-height: 50rem;
      // object-fit: cover;
      // margin-inline: auto;
      // border-radius: 50%;
    }
  }
}
