.primary-color {
  color: var(--primary-color);
}

.primary-color-2 {
  color: var(--primary-color-2);
}

.primary-color-3 {
  color: var(--primary-color-3) !important;
}

.secondary-color-2 {
  color: var(--secondary-color-2);
}

.danger-color {
  color: var(--danger-color);
}

.secondary-color-3 {
  color: var(--secondary-color-3);
}

.color-primary-light {
  color: $primary-color-2;
}

.color-primary-light-2 {
  color: $primary-color;
}

.color-secondary {
  color: $secondary-color;
}

.color-tertiary {
  color: $secondary-color-2;
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-principal {
  background-color: var(--background-color);
}

.bg-danger {
  background-color: var(--danger-color) !important;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.min-h-10 {
  min-height: 10rem;
}


.top-40 {
  top: 40% !important;
}

.top-33 {
  top: 33% !important;
}

.max-width-500 {
  max-width: 500px;
}
